import { GrowthBook } from "@growthbook/growthbook";

const getFeaturesJson = (featuresEndpoint) => {
  return fetch(featuresEndpoint)
    .then((response) => response.json());
};

export const GrowthBookVuePlugin = {
  install: function (app, { featuresEndpoint, enableDevMode = false }) {
    let growthBook = null;

    app.config.globalProperties.$initGrowthBook = async function initGrowthBook() {
      if (growthBook) {
        return Promise.resolve(growthBook);
      }

      return getFeaturesJson(featuresEndpoint)
        .then((json) => {
          growthBook = new GrowthBook({
            enableDevMode,
          });

          growthBook.setFeatures(json.features);

          return growthBook;
        })
        .catch((error) => {
          console.error("GrowthBook Vue plugin initialization error", error);
          return null;
        });
    };
  },
};
