<template>
  <page-sub-title-set-component
    :tabs="tabs"
    :current-tab="currentTab"
    @change-tab="changeTab"
  />
  <router-view></router-view>
</template>

<script>
import { mapState } from "vuex";
import PageSubTitleSetComponent from "@/components/PageSubTitleSetComponent";
import { AUTHORITY } from "@/common/constants";

export default {
  name: "Staff",

  components: {
    PageSubTitleSetComponent,
  },

  data() {
    return {
      currentTab: this.$route.name,

      tabs: [
        {
          name: "全従業員リスト",
          content: "staffList",
        },
        {
          name: "入力状況管理",
          content: "inputStatus",
        },
        {
          name: "更新対象者リスト",
          content: "updateTarget",
        },
      ],
    };
  },

  watch: {
    $route(to) {
      this.currentTab = to.name;
    },
  },

  computed: {
    ...mapState({
      companyInfo: (state) => JSON.parse(state.companyInfo).data,
    }),

    isApprover() {
      const adminInfo = JSON.parse(this.$store.state.adminInfo);
      return this.companyInfo.isThreeStepsApproval && adminInfo.authority !== AUTHORITY.GENERAL;
    },
    
    isApplicationGuestUse() {
      const companyInfo = JSON.parse(this.$store.state.companyInfo);
      return companyInfo ? companyInfo.data.isApplicationGuestUse : false;
    },
  },

  created() {
    if (this.isApplicationGuestUse) {
      const zaimaneTab = [
        {
          name: "アプリ読取リスト",
          content: "zaimane",
        },
      ];
      this.tabs = this.tabs.concat(zaimaneTab);
    }

    if(this.isApprover) {
      const approvalListTab = [
        {
          name: "承認待ちリスト",
          content: "approvalList",
        },
      ];
      this.tabs = this.tabs.concat(approvalListTab);
    }
  },

  methods: {
    changeTab(currentTab) {
      this.currentTab = currentTab;

      switch (this.currentTab) {
        case "staffList":
          this.$router.push({ name: "staffList" });
          break;
        case "inputStatus":
          this.$router.push({ name: "inputStatus" });
          break;
        case "updateTarget":
          this.$router.push({ name: "updateTarget" });
          break;
        case "updateApproval":
          this.$router.push({ name: "updateApproval" });
          break;
        case "zaimane":
          this.$router.push({ name: "zaimane" });
          break;
        case "approvalList":
          this.$router.push({ name: "approvalList" });
          break;
      }
    },
  },
};
</script>
