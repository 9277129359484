import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  updateAccountInfo(payload) {
    return ApiService.post(APIS.ADMIN_ACCOUNTINFO, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendVerificationMail(payload) {
    return ApiService.post(APIS.ADMIN_EMAIL, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updatePassword(payload) {
    return ApiService.post(APIS.ADMIN_PASSWORD, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get admin list
  getAdminList(params) {
    return ApiService.get(`${APIS.GET_ADMIN_LIST}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // add account admin
  addAdmin(data) {
    return ApiService.post(APIS.ADD_ADMIN, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get admin account
  getAdminAccount(idAdmin) {
    return ApiService.get(`${APIS.ADMIN_ACCOUNT}`.replace("{idAdmin}", idAdmin))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error;
      });
  },

  // update admin account
  updateAdminAccount(idAdmin, data) {
    return ApiService.post(
      `${APIS.ADMIN_ACCOUNT}`.replace("{idAdmin}", idAdmin),
      data
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error;
      });
  },

  // delete admin account
  deleteAdminAccount(idAdmin) {
    return ApiService.delete(
      `${APIS.ADMIN_ACCOUNT}`.replace("{idAdmin}", idAdmin)
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error;
      });
  },

  // verify email admin
  sendVerifyEmail(idAdmin) {
    return ApiService.post(APIS.VERIFY_EMAIL, { idAdmin })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  // verify email admin
  verifyEmailOnly(token) {
    return ApiService.post(APIS.VERIFY_EMAIL_ONLY, { token })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  // check exist email admin
  checkExistEmail(email) {
    return ApiService.post(APIS.CHECK_EXIST_EMAIL, { email })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  exportAdminCsvFile(params) {
    return ApiService.get(
      APIS.ADMIN_CSV,
      { params: params },
      {
        headers: {
          "Content-Type": "text/csv;charset=utf_8_sig",
          "Content-Disposition": 'attachment; filename="admins.csv"',
        },
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  importAdminCsvFile(formData) {
    return ApiService.post(APIS.ADMIN_CSV, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  resetPasswordMail(formData) {
    return ApiService.post(APIS.ADMIN_REPASSWORDMAIL, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get shop admin list
  getShopAdminList(idShop, params) {
    return ApiService.get(
      `${APIS.ADMIN_SHOP_LIST}`.replace("{idShop}", idShop),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get admin authority list
  getAdminAuthorityList(authority) {
    return ApiService.get(
      `${APIS.ADMIN_AUTHORITY_LIST}`.replace("{authority}", authority)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //post admin contractor
  postAdminContractor(idAdmin) {
    return ApiService.post(
      `${APIS.ADMIN_CONTRACTOR}`.replace("{idAdmin}", idAdmin)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getApproverList(idStaff) {
    return ApiService.get(
      `${APIS.ADMIN_GET_LIST_APPROVER}`.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get admin approval list/log
  getAdminApprovalLog(idAdmin, params) {
    return ApiService.get(`${APIS.ADMIN_APPROVAL_LOG}`.replace("{idAdmin}", idAdmin), {
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

};
