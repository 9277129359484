<template>
  <div v-if="isDataLoadDone" class="dashboard mr-5" :key="idCompany">
    <div v-if="adminInfo.newEmailAdmin" class="warning-message">
      <p>
        <v-icon>mdi-alert-outline</v-icon>
        新しいメールアドレスの認証が完了していません。
        変更時にお送りしているメールからアカウントの認証を完了させてください。
        またメールが見つからない場合は、
        <a @click="$router.push({ name: 'account' })">アカウント設定</a>
        から認証メールの再送をお試しください。
      </p>
    </div>
    <div v-else-if="!adminInfo.verified" class="warning-message">
      <p>
        <v-icon>mdi-alert-outline</v-icon>
        現在ご利用中アカウントのメールアドレスの認証が完了していません。
        登録時にお送りしているメールからアカウントの認証を完了させてください。
        またメールが見つからない場合は、
        <a @click="$router.push({ name: 'account' })">アカウント設定</a>
        から認証メールの再送をお試しください。
      </p>
    </div>
    <div
      v-if="
        adminInfo.emailAdmin === companyInfo.emailCompany &&
        (!companyInfo.isFillBaseInfo || !companyInfo.isFillPaymentInfo)
      "
      class="warning-message"
    >
      <p>
        <v-icon>mdi-alert-outline</v-icon>
        企業情報({{
          companyInfo.isFillBaseInfo
            ? "請求書受取先情報"
            : companyInfo.isFillPaymentInfo
            ? "基本情報"
            : "基本情報、請求書受取先情報"
        }})が不足しています。
        <a @click="$router.push({ name: 'companyInfoSetting' })">こちら</a>
        から情報の入力を完了させてください。
      </p>
    </div>

    <div
      v-if="
        companyInfo.levelPlan == levelPlan.FREE &&
        !(adminInfo.authority == authority.MASTER && !isStaffExist)
      "
      class="d-flex align-center box-shadow border-8 w-100 my-4"
    >
      <div class="d-flex justify-space-between w-100 px-4">
        <div
          :class="{
            'my-4': adminInfo.authority == authority.MASTER,
            'my-6': adminInfo.authority != authority.MASTER,
          }"
        >
          <p class="text-danger text-bold pb-2">
            フリープランのご登録ありがとうございます。
          </p>
          <p class="text-white-smoke fs-14">
            フリープランではビザマネのベーシックプランと同じ機能を従業員30名様までご利用いただけます。<br />
            {{
              adminInfo.authority == authority.MASTER
                ? "従業員を31名以上ご登録したい場合はプラン変更をしてください。"
                : ""
            }}
          </p>
        </div>
        <div class="mt-8">
          <div
            v-if="adminInfo.authority != authority.MASTER"
            class="button-danger py-5 fs-16"
          >
            <a
              class="d-flex"
              href="https://visamane.zendesk.com/hc/ja"
              target="_blank"
            >
              <img class="w-3" :src="require(`@/assets/img/lightbulb.svg`)" />
              <p class="pl-2">ビザマネの使い方を知る</p>
            </a>
          </div>
          <a
            v-if="adminInfo.authority == authority.MASTER"
            class="button-danger fs-16 d-inline-block"
            @click="$refs.planModal.open()"
          >
            プランの変更はこちらから
          </a>
        </div>
      </div>
    </div>

    <div
      class="
        bg-grey-light-3
        text-dark-light
        fs-12
        d-flex
        flex-column
        align-center
      "
      v-if="
        companyInfo.explainAction &&
        (companyInfo.explainAction.includes(explainActionConst.TRIAL_END) ||
          companyInfo.explainAction.includes(
            explainActionConst.TRIAL_END_CONTRACTOR
          ) ||
          companyInfo.explainAction.includes(
            explainActionConst.ONGOING_CONTRACTOR
          ) ||
          companyInfo.explainAction.includes(explainActionConst.ONGOING) ||
          companyInfo.explainAction.includes(
            explainActionConst.REJECTED_CONTRACTOR
          ) ||
          companyInfo.explainAction.includes(explainActionConst.REJECTED))
      "
    >
      <p class="text-bold text-danger fs-14 pt-2">注意</p>
      <div class="border-bottom-white-grey w-100"></div>

      <div
        class="d-flex flex-column align-center"
        v-if="
          companyInfo.explainAction &&
          (companyInfo.explainAction.includes(explainActionConst.TRIAL_END) ||
            companyInfo.explainAction.includes(
              explainActionConst.TRIAL_END_CONTRACTOR
            ) ||
            companyInfo.explainAction.includes(
              explainActionConst.ONGOING_CONTRACTOR
            ) ||
            companyInfo.explainAction.includes(explainActionConst.ONGOING) ||
            companyInfo.explainAction.includes(
              explainActionConst.REJECTED_CONTRACTOR
            ) ||
            companyInfo.explainAction.includes(explainActionConst.REJECTED))
        "
      >
        <p class="my-2">
          現在ご契約中のプラン:
          <span class="text-bold mr-5">{{ companyInfo.namePlan }}</span>
          ご登録従業員数:
          <span class="text-bold">
            {{ alert && alert.paymentStaff ? alert.paymentStaff : 0 }}名
          </span>
        </p>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(explainActionConst.TRIAL_END)
          "
        >
          現在ご契約中にプランには、従業員数に制限(30名まで)がかかっております。
          <br />
          アドミンアカウントをお持ちの担当者様に現在ご利用中のプランについてご確認ください。
        </div>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(
              explainActionConst.TRIAL_END_CONTRACTOR
            )
          "
        >
          従業員数制限(30名まで)が解除されておりません。プラン変更の場合は
          <a
            class="text-danger text-decoration-underline"
            @click="$refs.planModal.open()"
          >
            こちらをクリック
          </a>
          ください。
          <br />
          その他の内容については
          <span
            class="text-danger cursor-pointer"
            @click="
            $router.push({
              name: 'contact'}) && 
                setStateParamsContact({
                  subject: 'プラン変更について',
                })
          "
            >お問い合わせ </span
          >ください
        </div>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(
              explainActionConst.ONGOING_CONTRACTOR
            )
          "
        >
          お客様のアカウントの登録情報確認為、一時的に従業員数に制限(30名まで)がかかっております。
          <br />
          登録情報の確認が取れ次第、制限は自動で解除されますが、１週間以上制限の解除がされない場合は、<a
            class="text-danger text-decoration-underline"
            @click="
            $router.push({
              name: 'contact'}) && 
                setStateParamsContact({
                  subject: 'プラン変更について',
                })
          "
            >お問い合わせ</a
          >ください。
        </div>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(explainActionConst.ONGOING)
          "
        >
          現在ご契約中にプランには、従業員数に制限(30名まで)がかかっております。
          <br />
          アドミンアカウントをお持ちの担当者様に現在ご利用中のプランについてご確認ください。
        </div>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(
              explainActionConst.REJECTED_CONTRACTOR
            )
          "
        >
          お客様のアカウントの登録情報確認取れなかった為、従業員数制限(30名まで)の解除ができませんでした。
          <br />
          詳しい内容/解除方法等は、<a
            class="text-danger text-decoration-underline"
            @click="
            $router.push({
              name: 'contact'}) && 
                setStateParamsContact({
                  subject: 'プラン変更について',
                })
          "
            >お問い合わせ</a
          >ください。
        </div>

        <div
          class="pb-3"
          v-if="
            companyInfo.explainAction &&
            companyInfo.explainAction.includes(explainActionConst.REJECTED)
          "
        >
          お客様のアカウントの登録情報確認取れなかった為、従業員数制限(30名まで)の解除ができませんでした。
          <br />
          <span>
            詳しい内容/解除方法等は、契約者アカウントをお持ちの担当者様に現在ご利用中のプランについてご確認ください
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="
        !isStaffExist &&
        ((companyInfo.levelPlan == levelPlan.FREE &&
          adminInfo.authority == authority.MASTER) ||
          companyInfo.levelPlan != levelPlan.FREE)
      "
      class="d-inline-block w-100 mt-6"
    >
      <div class="float-left">
        <p class="text-red fs-24 text-bold">ビザマネへようこそ</p>
        <p class="text-grey fs-12">ビザマネを始めましょう。</p>
      </div>
      <div class="float-right">
        <div class="button-danger py-5 fs-16">
          <a
            class="d-flex"
            href="https://visamane.zendesk.com/hc/ja"
            target="_blank"
          >
            <img class="w-3" :src="require(`@/assets/img/lightbulb.svg`)" />
            <p class="pl-2">ビザマネの使い方を知る</p>
          </a>
        </div>
      </div>
    </div>

    <div v-if="isStaffExist" class="d-flex mt-8 mb-5">
      <a
        class="d-flex button-danger w-25 h-4-important fs-16 mr-4"
        @click="openModalAddStaff()"
      >
        <img class="w-3" :src="require(`@/assets/img/add_employee.svg`)" />
        <p class="pl-2">従業員を登録する</p>
      </a>
      <a
        v-if="adminInfo.authority == authority.MASTER"
        class="d-flex button-danger w-25 h-4-important fs-16 mr-4"
        @click="$refs.modalAddShop.open()"
      >
        <img class="w-3" :src="require(`@/assets/img/add_shop.svg`)" />
        <p class="pl-2">店舗を追加する</p>
      </a>
      <a
        v-if="adminInfo.authority == authority.MASTER"
        class="d-flex button-danger w-25 h-4-important fs-16 mr-4"
        @click="$refs.modalAddAdmin.open()"
      >
        <img class="w-3" :src="require(`@/assets/img/add_user.svg`)" />
        <p class="pl-2">ユーザーを追加する</p>
      </a>
      <div
        v-if="
          companyInfo.levelPlan != levelPlan.FREE ||
          (companyInfo.levelPlan == levelPlan.FREE &&
            adminInfo.authority == authority.MASTER)
        "
        class="d-flex button-danger w-25 h-4-important fs-16"
      >
        <a
          class="d-flex"
          href="https://visamane.zendesk.com/hc/ja"
          target="_blank"
        >
          <img class="w-3" :src="require(`@/assets/img/lightbulb.svg`)" />
          <p class="pl-2">ビザマネの使い方を知る</p>
        </a>
      </div>
    </div>

    <div v-if="!isStaffExist" class="v-row text-center mt-4">
      <div class="v-col-7 pr-5">
        <div
          class="box-shadow hover-border-line h-100 border-8 cursor-pointer"
          style="height: 284px"
          @click="openModalAddStaff()"
        >
          <img
            class="w-8 pt-10"
            :src="require(`@/assets/img/add_employee.svg`)"
          />
          <p class="text-dark-light fs-20 text-bold">従業員を登録する</p>
          <p class="text-grey fs-12">
            QRコードを読み取って従業員を登録しましょう。
          </p>
          <p
            class="
              button-bg-danger
              border-none
              d-inline-block
              font-weight-bold
              fs-16
              mt-2
              px-12
            "
          >
            登録する
          </p>
        </div>
      </div>
      <div
        v-if="adminInfo.authority == authority.MASTER"
        class="v-col-5 text-start"
      >
        <div
          class="
            d-flex
            align-items-center
            box-shadow
            hover-border-line
            h-50
            border-8
            cursor-pointer
            py-1
          "
          style="margin-bottom: 30px"
          @click="$refs.modalAddShop.open()"
        >
          <div class="v-col-3 d-inline-block pt-5 pl-6">
            <img class="w-8" :src="require(`@/assets/img/add_shop.svg`)" />
          </div>
          <div class="v-col-8 d-inline-block">
            <p class="text-dark-light fs-20 text-bold pb-2">店舗を追加する</p>
            <p class="text-grey fs-12">
              従業員が所属する店舗を追加して、店舗ごとに従業員を管理できるようにしましょう。
            </p>
          </div>
          <div class="v-col-1 d-inline-block">
            <v-icon class="text-grey" style="font-size: 4rem">
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
        <div
          class="
            d-flex
            align-items-center
            box-shadow
            hover-border-line
            h-50
            border-8
            cursor-pointer
            py-1
          "
          @click="$refs.modalAddAdmin.open()"
        >
          <div class="v-col-3 d-inline-block pt-5 pl-6">
            <img class="w-8" :src="require(`@/assets/img/add_user.svg`)" />
          </div>
          <div class="v-col-8 d-inline-block">
            <p class="text-dark-light fs-20 text-bold pb-2">
              ユーザーを追加する
            </p>
            <p class="text-grey fs-12">
              ユーザーを追加して店舗ごとに管理者を置いて、店舗ごとの管理を楽にしましょう。
            </p>
          </div>
          <div class="v-col-1 d-inline-block">
            <v-icon class="text-grey" style="font-size: 4rem">
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </div>
      <div
        v-if="adminInfo.authority != authority.MASTER"
        class="v-col-5 text-start"
      >
        <div class="h-100 border border-8 border-white-grey">
          <p class="fs-16 text-white-smoke text-bold pt-4 px-4">
            アプリダウンロード
          </p>
          <p class="fs-12 text-grey pt-2 px-4">
            在留カードの偽造チェックができるアプリはこちらから
          </p>
          <div class="qr-code-modal d-flex justify-center">
            <div class="text-center pr-8">
              <div class="qr-code-small">
                <qrcode-component
                  :value="'https://apps.apple.com/jp/app/id1533068341'"
                  :options="{ width: 110 }"
                />
              </div>
              <p class="fs-12 text-grey">iOS</p>
            </div>
            <div class="text-center">
              <div class="qr-code-small">
                <qrcode-component
                  :value="'https://play.google.com/store/apps/details?id=jp.co.willgroup.visamane'"
                  :options="{ width: 110 }"
                />
              </div>
              <p class="fs-12 text-grey">Android</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isStaffExist && adminInfo.authority == authority.MASTER"
      class="v-row text-center mt-5"
    >
      <div
        v-if="companyInfo.levelPlan == levelPlan.FREE"
        class="v-col-6 text-start pr-5"
      >
        <div class="h-100 border border-8 border-white-grey">
          <p class="fs-16 text-red text-bold pt-6 pb-4 pl-4">
            フリープランのご登録ありがとうございます。
          </p>
          <p class="fs-14 text-white-smoke px-4 pb-8">
            フリープランではビザマネのベーシックプランと同じ機能を従業員30名様までご利用いただけます。<br />
            従業員を31名以上ご登録したい場合はプラン変更をしてください。
          </p>
          <div class="text-center pb-6">
            <a
              class="button-danger fs-16 d-inline-block"
              @click="$refs.planModal.open()"
            >
              プランの変更はこちらから
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="companyInfo.levelPlan == levelPlan.FREE"
        class="v-col-6 text-start"
      >
        <div class="h-100 border border-8 border-white-grey">
          <p class="fs-16 text-white-smoke text-bold pt-4 px-4">
            アプリダウンロード
          </p>
          <p class="fs-12 text-grey pt-2 px-4">
            在留カードの偽造チェックができるアプリはこちらから
          </p>
          <div class="qr-code-modal d-flex justify-center">
            <div class="text-center pr-8">
              <div class="qr-code-small">
                <qrcode-component
                  :value="'https://apps.apple.com/jp/app/id1533068341'"
                  :options="{ width: 110 }"
                />
              </div>
              <p class="fs-12 text-grey">iOS</p>
            </div>
            <div class="text-center">
              <div class="qr-code-small">
                <qrcode-component
                  :value="'https://play.google.com/store/apps/details?id=jp.co.willgroup.visamane'"
                  :options="{ width: 110 }"
                />
              </div>
              <p class="fs-12 text-grey">Android</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="companyInfo.levelPlan != levelPlan.FREE"
        class="v-col-12 text-start"
      >
        <div
          class="
            d-flex
            justify-space-between
            border border-8 border-white-grey
            px-4
          "
        >
          <div>
            <p class="fs-16 text-white-smoke text-bold pt-4">
              アプリダウンロード
            </p>
            <p class="fs-12 text-grey pt-2">
              在留カードの偽造チェックができるアプリはこちらから
            </p>
          </div>
          <div class="mb-3">
            <div class="qr-code-modal d-flex justify-center">
              <div class="text-center pr-8">
                <div class="qr-code-small">
                  <qrcode-component
                    :value="'https://apps.apple.com/jp/app/id1533068341'"
                    :options="{ width: 110 }"
                  />
                </div>
                <p class="fs-12 text-grey">iOS</p>
              </div>
              <div class="text-center">
                <div class="qr-code-small">
                  <qrcode-component
                    :value="'https://play.google.com/store/apps/details?id=jp.co.willgroup.visamane'"
                    :options="{ width: 110 }"
                  />
                </div>
                <p class="fs-12 text-grey">Android</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-component
      ref="planModal"
      class="plan-modal"
      :max-width="1130"
      :header="false"
      :footer="false"
    >
      <template #body>
        <div>
          <p class="text-danger font-weight-bold fs-24">
            ご興味のあるプランをお選びください。
          </p>
          <p class="text-white-smoke text-left mb-10 mt-5">
            ベーシックプラン以外への変更はお問い合わせにてご連絡いただき、担当者から折り返しご連絡させていただきます
          </p>
          <div class="v-row">
            <div class="v-col-3 px-2">
              <div class="border-white-grey border-8 h-95">
                <p class="text-dark-light font-weight-bold fs-18 py-1">
                  フリー
                </p>
                <div class="border-bottom-white-grey my-0"></div>
                <div class="text-white-smoke pa-3">
                  <p class="text-danger font-weight-bold fs-24 h-6">無料</p>
                  <div class="text-left fs-14">
                    <p class="pb-5">
                      まずは無料で試してみたい方向けにベーシックプランの機能を登録従業員30名以下限定で無料でお使いいただけるプランです。
                    </p>
                    <p class="pb-5">
                      外国人雇用人数が30名以下の企業様向けのプランです。
                    </p>
                    <p>
                      21日間のトライアル期間終了後は自動的にこちらのプランへ移行します。
                    </p>
                  </div>
                </div>
              </div>
              <p
                class="
                  d-flex
                  justify-center
                  align-center
                  mt-4
                  h-36px
                  font-weight-bold
                  text-dark-light
                  cursor-pointer
                "
                @click="$refs.planModal.close()"
              >
                現在のプラン
              </p>
            </div>
            <div class="v-col-3 px-2">
              <div class="border-white-grey border-8 h-95">
                <p
                  class="
                    bg-danger
                    border-tr-8
                    text-white
                    font-weight-bold
                    fs-18
                    py-1
                  "
                >
                  ベージック
                </p>
                <div class="border-bottom-white-grey my-0"></div>
                <div class="text-white-smoke px-3">
                  <div class="h-6 mb-3">
                    <p class="fs-12 pt-1">31名から従業員1名あたり</p>
                    <p class="text-danger font-weight-bold fs-24">
                      300<span class="fs-14">円</span>
                    </p>
                  </div>

                  <div class="text-left fs-14">
                    <p class="pb-5">
                      外国籍雇用に必要な在留カード偽造チェックから在留カード管理を一気通貫でより簡単に、より安心して行っていただけるプランです。
                    </p>
                    <p class="pb-5">
                      120名以下の規模で外国人を雇用している企業様向けのスタンダードなプランです。
                    </p>
                    <p>以下の機能がご利用いただけます。</p>
                    <p class="fs-12 font-weight-bold pb-3">
                      ・ICチップ読み取りでの在留カード偽造<span class="ml-3"
                        >チェック</span
                      >
                      <br />
                      ・就労可否判定<br />
                      ・入管法遵守の誓約書回収<br />
                      ・在留カード情報のグラウド管理<br />
                      ・在留カード期限アラートメール自動送信<br />
                      ・従業員情報のCSVでのデータ出力<br />
                      ・外国人雇用状況提出所一括作成
                    </p>
                    <p>
                      登録従業員30名までは、<span class="text-danger">無料</span
                      >でご利用いただけます。
                    </p>
                    <p class="fs-12">最低利用料金は、9,300円からとなります。</p>
                  </div>
                </div>
              </div>
              <p
                class="button-danger mt-4"
                @click="$refs.planModal.close(), $refs.confirmModal.open()"
              >
                このプランにする
              </p>
            </div>

            <div class="v-col-3 px-2">
              <div class="border-white-grey border-8 h-95">
                <p class="text-dark-light font-weight-bold fs-18 py-1">
                  ビジネス
                </p>
                <div class="border-bottom-white-grey my-0"></div>
                <div class="text-white-smoke pa-3">
                  <p class="text-danger font-weight-bold fs-24 h-6">
                    <span class="fs-14">月</span>35,000<span class="fs-14"
                      >円~</span
                    >
                  </p>
                  <div class="text-left fs-14">
                    <p class="pb-5">
                      外国籍従業員に提出してもらう書類や項目をカスタマイズ・データ出力のフォーマット変更などを行えます。
                    </p>
                    <p class="pb-5">
                      120名以上外国人を雇用しており、カスタマイズして利用したい企業様向けのプランです。
                    </p>
                    <p>以下の機能がご利用いただけます</p>
                    <p class="fs-12 font-weight-bold">
                      ・ベーシックプランの機能すべて<br />
                      ・必須提出書類項/カスタマイズ項目の追加<br />
                      ・CSVデータ出力フォーマットのカスタマイズ
                    </p>
                  </div>
                </div>
              </div>
              <p
                class="button-danger mt-4"
                @click="
                  $router.push({
                    name: 'contact'}) && 
                      setStateParamsContact({
                        subject: 'プラン変更について',
                        content: 'ビジネスプランへの変更を希望します',
                      })
                "
              >
                このプランにする
              </p>
            </div>
            <div class="v-col-3 px-2">
              <div class="border-white-grey border-8 h-95">
                <p class="text-dark-light font-weight-bold fs-18 py-1">
                  エンタープライズ
                </p>
                <div class="border-bottom-white-grey my-0"></div>
                <div class="text-white-smoke pa-3">
                  <p class="text-danger font-weight-bold fs-24 h-6">要相談</p>

                  <div class="text-left fs-14">
                    <p class="pb-15">
                      ビジネスプランのすべての機能を利用いただけ、さらにAPI連携や従業員データ入力代行、セキュリティ設定などの対応をいたします。セキュリティ強化やBPOを希望する企業様向けのプランです。
                    </p>
                    <p>以下の機能がご利用いただけます</p>
                    <p class="fs-12 font-weight-bold">
                      ・ビジネスプランの機能すべて<br />
                      ・ビジネスアプリ上の表示情報制限<br />
                      ・IPアドレスアクセス制限
                    </p>
                  </div>
                </div>
              </div>
              <p
                class="button-danger mt-4"
                @click="
                  $router.push({
                    name: 'contact'}) &&
                    setStateParamsContact({
                      subject: 'プラン変更について',
                      content: 'エンタープライズプランへの変更を希望します',
                    })
                "
              >
                このプランにする
              </p>
            </div>
          </div>

          <p
            class="text-danger cursor-pointer mt-16"
            @click="$refs.planModal.close()"
          >
            キャンセル
          </p>
        </div>
      </template>
    </modal-component>

    <modal-component ref="confirmModal" class="confirm-modal" :max-width="850">
      <template #header>
        <p>ベーシックプランへの変更</p>
      </template>
      <template #body>
        <div class="text-left text-white-smoke fs-14 mx-4">
          <p class="font-weight-bold mb-5">
            ベーシックプランへ変更に関する注意点をご確認の上、変更するボタンをクリックしてください
          </p>
          <div>
            <div class="text-dark-light font-weight-bold">
              1. <span class="ml-3">料金計算方法/適用されるタイミング</span>
            </div>
            <div class="ml-7">
              <div>
                ◦
                <span class="ml-2"
                  >プランをベーシックプランにアップグレードする場合</span
                >
              </div>
              <div class="ml-6">
                <div>
                  ▪
                  <span class="ml-2"
                    >料金はすぐにベーシックプランの金額となります。</span
                  >
                </div>
                <div>
                  ▪
                  <span class="ml-2"
                    >機能をプラン変更後すぐにご利用いただけます。</span
                  >
                </div>
              </div>
              <div>
                ◦<span class="ml-2"
                  >プランをベーシックプランにダウングレードする場合。</span
                >
              </div>
              <div class="ml-6">
                ▪
                <span class="ml-2"
                  >料金変更はプラン変更日を含む月の翌月度から適用となります。</span
                >
              </div>
              <div class="fs-12 ml-12">
                <div>※変更による日割り計算はございません。</div>
                <div>
                  現在お使いのプランの機能をプラン変更日を含む含む月末までご利用いただけます。
                </div>
                <div>
                  機能の制限はプラン変更日を含む月の翌月1日から適用となります。
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="text-dark-light font-weight-bold mt-10">
              2. <span class="ml-3">料金発生が発生する対象人数</span>
            </div>
            <div class="ml-7">
              <div>
                ◦
                <span class="ml-2"
                  >当該月末時点の請求対象人数(ステータスが入社待ち、入社、定職中)の従業員人数が対象となります。</span
                >
              </div>
              <div>
                ◦<span class="ml-2"
                  >また、その月にステータスが退職になった人数は請求対象人数ににカウントされます。</span
                >
                <div class="fs-12 ml-6">
                  ※変更による日割り計算はございません。
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-center my-3">
          <p class="button-danger w-14" @click="$refs.confirmModal.close()">
            変更しない
          </p>
          <p class="button-bg-danger w-14 ml-3" @click="onPostPlanSelect">
            変更する
          </p>
        </div>
      </template>
    </modal-component>

    <modal-component
      ref="resultModal"
      class="result-modal"
      :max-width="640"
      :header="false"
      :footer="false"
      :modal-data="errorData"
    >
      <template #body>
        <div class="pa-4" v-if="!errorData">
          <div class="logo pt-8 pb-2">
            <Logo />
          </div>

          <div v-if="creditStatus == 'Passed'">
            <p class="font-weight-bold text-white-smoke fs-24 py-3">
              従業員数制限が解除されました
            </p>
            <div class="text-left text-white-smoke">
              <p class="pb-4">
                ビザマネをご利用いただき、誠にありがとうございます。
              </p>
              <p class="pb-4">
                ご契約者様の従業員数制限が解除されましたので、従業員を30名以上登録し
                <br />ていただくことができます。ベーシックプランでは、登録従業員31名から月額
                <br />料金が課金されます。
              </p>
              <p class="pb-16">
                プランの変更をご検討の場合は、
                <span class="text-danger text-decoration-underline">
                  こちら
                </span>
                からお問い合わせください。
              </p>
            </div>
          </div>
          <div v-else>
            <p class="font-weight-bold text-white-smoke fs-24 py-3">
              ベジックプランへ変更しました
            </p>
            <div class="text-left text-white-smoke">
              <p class="pb-4">
                ビザマネをご利用いただき、誠にありがとうございます。
              </p>
              <p class="pb-4">
                ご契約のプランをフリープランからベージックプランに変更いたしました。
                <br />ご契約者様のアカウント登録情報の確認中のため一時的に従業員の登録制限
                <br />(30名以下)がかかっております。確認が取れ次第、31名以上登録していただく
                <br />ことが出来ます。ベージックプランでは、登録従業員数31名から月額料金が課
                <br />金されます。
              </p>
              <p class="pb-16">
                ビジネスプランまたはエンタープライズプランへ変更をご検討の場合は、
                <br />
                <span class="text-danger text-decoration-underline"
                  >こちら</span
                >
                からお問い合わせください。
              </p>
            </div>
          </div>

          <p
            class="button-bg-danger mx-auto h-56px w-30 fs-20 my-3"
            @click="$refs.resultModal.close()"
          >
            ダッシュボードへ
          </p>
        </div>
      </template>
    </modal-component>

    <div class="v-row mt-5 fs-14" v-if="isStaffExist && alert && helloWork">
      <div class="v-col-4 pr-4">
        <div class="board-info bg-grey-light-3 pa-4 h-100 box-shadow">
          <p class="font-weight-bold text-white-smoke fs-16 mb-3">アラート</p>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-danger">mdi-cancel</v-icon>警告
            </p>
            <p class="text-danger mw-6 text-right">
              <b>{{ alert.warning }}</b>
              <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                alert.warning
                  ? 'text-danger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.warning &&
                  $router.push({name: 'staffList'}) && 
                  this.setStateParamsStaffList({
                    alertType: 'Warning', 
                    currentStatus: 'Working,Hired,Suspended,Status update needed'
                  })
              "
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-danger">mdi-alert</v-icon>不備あり
            </p>
            <p class="text-danger mw-6 text-right">
              <b>{{ alert.missingInformation }}</b>
              <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                alert.missingInformation
                  ? 'text-danger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.missingInformation &&
                  $router.push({
                    name: 'inputStatus' }) && 
                    this.setStateParamsInputStatus({
                      alertName: 'Has request,Saved temporary,TBD,Not assigned'
                    })
              "
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-warning">mdi-bell</v-icon>要確認
            </p>
            <p class="text-warning mw-6 text-right">
              <b>{{ alert.attentionNeeded }}</b>
              <span class="fs-11">人</span>
            </p>

            <p
              class="text-right"
              :class="
                alert.attentionNeeded
                  ? 'text-warning cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.attentionNeeded &&
                  $router.push({name: 'staffList'}) &&
                   this.setStateParamsStaffList({
                      alertType: 'Attention Needed',
                      currentStatus: 'Working,Hired,Suspended,Status update needed',
                    })
              "
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-dodger">mdi-refresh</v-icon>更新対象
            </p>
            <p class="text-dodger mw-6 text-right">
              <b>{{ alert.updateNeeded }}</b>
              <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                alert.updateNeeded
                  ? 'text-dodger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.updateNeeded &&
                  $router.push({name: 'updateTarget' }) && 
                  this.setStateParamsUpdateTarget({
                    alertName: 'Within 3 months,Within 3 months (Extended),Within 2 months,Within 2 months (Extended),Within 1 months,Within 1 months (Extended),Within 2 weeks,Within 2 weeks (Extended),Within 1 week,Within 1 week (Extended)'
                    })
              "
            >
              確認する
            </p>
          </div>
        </div>
      </div>
      <div class="v-col-4 px-4">
        <div class="board-info bg-grey-light-3 pa-4 h-100 box-shadow">
          <p class="font-weight-bold text-white-smoke fs-16 mb-3">
            外国人雇用状況届出書
          </p>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-danger"> mdi-calendar-remove </v-icon>
              提出期限超過
            </p>
            <p class="text-danger mw-6 text-right">
              <b>{{ helloWork.deadline }}</b>
              <span class="fs-11">人</span>
            </p>

            <p
              class="text-right"
              :class="
                helloWork.deadline
                  ? 'text-danger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                helloWork.deadline &&
                  $router.push({
                    name: 'helloworkElectronic'}) && 
                  this.setStateParamsElectronic({ onlyExpired: true })"
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-warning"> mdi-calendar-minus </v-icon>
              今月提出分
            </p>
            <p class="text-warning mw-6 text-right">
              <b>{{ helloWork.currentMonth }}</b>
              <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                helloWork.currentMonth
                  ? 'text-warning cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                helloWork.currentMonth &&
                  $router.push({
                    name: 'helloworkElectronic',
                  })
              "
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-dodger"> mdi-calendar-blank </v-icon>
              来月提出分
            </p>
            <p class="text-dodger mw-6 text-right">
              <b>{{ helloWork.nextMonth }}</b>
              <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                helloWork.nextMonth
                  ? 'text-dodger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
              helloWork.nextMonth &&
                  $router.push({
                    name: 'helloworkElectronic'}) && this.setStateParamsElectronic({ deadline: getNextMonth() })
              "
            >
              確認する
            </p>
          </div>
        </div>
      </div>
      <div class="v-col-4 pl-4">
        <div class="board-info bg-grey-light-3 pa-4 h-100 box-shadow">
          <p class="font-weight-bold text-white-smoke fs-16 mb-3">その他</p>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon class="text-danger">mdi-office-building</v-icon
              >アラート店舗
            </p>
            <p class="text-danger mw-6 text-right">
              <b>{{ alert.shopAlert }}</b>
              <span class="fs-11">店舗</span>
            </p>
            <p
              class="text-right"
              :class="
                alert.shopAlert
                  ? 'text-danger cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.shopAlert &&
                  $router.push({
                    name: 'shopList'}) && this.setStateParamsShopFilter({ isAlertExist: true })
              "
            >
              確認する
            </p>
          </div>
          <div class="border-bottom-white-grey"></div>
          <div class="d-flex justify-space-between">
            <p class="text-white-smoke mw-11">
              <v-icon> mdi-account-multiple </v-icon>
              在籍従業員
            </p>
            <p class="text-white-smoke mw-6 text-right">
              <b>{{ alert.staff }} </b> <span class="fs-11">人</span>
            </p>
            <p
              class="text-right"
              :class="
                alert.staff
                  ? 'text-white-smoke cursor-pointer'
                  : 'text-color-gainsboro'
              "
              @click="
                alert.staff &&
                  $router.push({ name: 'staffList' })
              "
            >
              確認する
            </p>
          </div>
        </div>
      </div>
    </div>

    <default-card-component
      class="default-card__table mt-8 mb-2"
      v-if="isStaffExist"
    >
      <template #header>
        <div class="d-flex align-center">
          <div class="py-3">
            <b>従業員リスト</b>
            <span class="total">全{{ getTotalItems(totalItems) }}名</span>
          </div>
        </div>
      </template>

      <template #content>
        <div class="store-list-table text-center">
          <page-list-filter-component
            :key="filters"
            :filters="filters"
            @changeInput="getSearchParams"
            @changeOption="getSearchParams"
          />

          <page-list-component
            :columns="columns"
            :items="staffList"
            :selection="false"
            no-data-text="従業員がいません"
            @select-row="
              $router.push({ name: 'staffBasicInfo' }),
                saveSelectedStaff($event.idStaff)
            "
          >
            <template #staffID="{ data }">
              <p>{{ data.staffID }}</p>
            </template>

            <template #nameStaffRegister="{ data }">
              <p class="font-weight-bold text-dark-light">
                {{
                  data.nameStaffRegister
                    ? data.nameStaffRegister.trim()
                      ? data.nameStaffRegister
                      : data.nameStaff
                    : data.nameStaff
                }}
              </p>
            </template>

            <template #currentStatus="{ data }">
              <p>{{ getCurrentStatus(data.currentStatus) }}</p>
            </template>

            <template #isReported="{ data }">
              <p>
                {{
                  data.isReported
                    ? "偽造の可能性あり"
                    : data.isReported === false
                    ? "問題なし"
                    : "未確認"
                }}
              </p>
            </template>

            <template #dataInputStatus="{ data }">
              <p>
                {{ getDataInputStatus(data.dataInputStatus) }}
              </p>
            </template>

            <template #alerts="{ data }">
              <div v-if="data.alerts">
                <span
                  class="font-weight-bold mr-4"
                  :style="{ color: getColorAlert(alert.type) }"
                  v-for="(alert, index) in data.alerts"
                  :key="index"
                >
                  {{ getAlert(alert.alertSpecification) }}
                </span>
              </div>

              <span class="font-weight-bold" v-else>アラートなし</span>
            </template>
          </page-list-component>

          <br />
          <page-pager-component
            v-if="totalPages"
            :current-page="currentPage"
            :total-page="totalPages"
            @change-page="changePage"
          />
        </div>
      </template>
    </default-card-component>

    <ModalFirstLogin
      :popupAction="companyInfo.popupAction"
      @planModal="$refs.planModal.open()"
      @getCompanyInfo="onGetCompanyInfo()"
    />

    <!-- 店舗追加関連ポップアップ ----------------------------------------------------------------------------------------->
    <modal-component @close="closeModalAddShop" ref="modalAddShop">
      <template #header>店舗追加</template>

      <template #body class="modal-body">
        <div class="text-left fs-16 mx-8">
          <p class="text-white-smoke">
            追加したい店舗の情報を入力して店舗追加ボタンをクリックしてください。
          </p>
          <a
            href="https://visamane.zendesk.com/hc/ja/articles/4407093260697"
            target="_blank"
          >
            <p class="text-danger">ヘルプを見る</p>
          </a>
        </div>

        <div class="text-left mx-5">
          <div class="mt-5">
            <input-component
              placeholder="店舗番号"
              :value="formAddShop.shopID"
              @handleChange="formAddShop.shopID = $event"
            />
          </div>

          <div class="mt-5">
            <input-component
              placeholder="店舗名"
              :value="formAddShop.nameShop"
              :required="true"
              :redBackground="false"
              :error="errorNameShop"
              @handle-change="changeNameShop"
            />
          </div>

          <div class="mt-5" v-if="officeOnlyList.length">
            <page-list-filter-pulldown-component
              :items="officeOnlyList"
              item-text="officeName"
              item-value="idOffice"
              :value="formAddShop.idOffice"
              placeholder="雇入れ事業所"
              @selected="formAddShop.idOffice = $event ? $event : ''"
              noDataText="該当する雇入れ事業所はありません"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-center">
          <v-btn
            class="fs-14 w-14 border-8"
            variant="outlined"
            :color="btnColor.CANCEL"
            @click="$refs.modalAddShop.close()"
          >
            キャンセル
          </v-btn>
          <v-btn
            :class="[
              'w-14 ml-3 fs-14 btn border-8',
              { 'button-disabled': !activeButtonAddShop },
            ]"
            variant="outlined"
            :color="btnColor.SET"
            :disabled="!activeButtonAddShop"
            @click="handleAddShop()"
          >
            店舗追加
          </v-btn>
        </div>
      </template>
    </modal-component>

    <modal-component ref="modalConfirmCloseAddShop" :max-width="500">
      <template #header>店舗追加の中断</template>
      <template #body>
        <p class="fw-400 text-white-smoke">
          変更内容が保存されていません。
          <br />
          モーダルを閉じますか？
        </p>
      </template>
      <template #footer>
        <div class="text-center">
          <v-btn
            class="fs-14 w-14 btn-confirm"
            variant="outlined"
            color="#F50057"
            @click="
              $refs.modalConfirmCloseAddShop.close();
              $refs.modalAddShop.open();
            "
          >
            いいえ
          </v-btn>
          <v-btn
            :class="['w-14 ml-3 fs-14 btn btn-confirm']"
            variant="outlined"
            color="#1565C0"
            @click="$refs.modalConfirmCloseAddShop.close()"
          >
            はい
          </v-btn>
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="shopAddCompleteModal"
      ref="shopAddCompleteModal"
      @handleBackShopDetail="handleBackShopDetail"
      @handleLinkAll="handleBackShopDetail()"
    >
      <template #footer>
        <p class="fs-14 ml-3 px-5 pt-2 text-center">
          <a class="download-link" @click="$refs.shopAddCompleteModal.close()">
            ダッシュボードへ戻る
          </a>
        </p>
      </template>
    </modal-component>
    <!-- 店舗追加関連ポップアップ ----------------------------------------------------------------------------------------->

    <!-- ユーザー追加関連ポップアップ -------------------------------------------------------------------------------------->
    <modal-component @close="closeModalAddAdmin" ref="modalAddAdmin">
      <template #header>ユーザー追加</template>

      <template #body class="modal-body">
        <div class="form-user-add">
          <div class="input username">
            <InputComponent
              name="nameAdmin"
              :value="formAddAdmin.nameAdmin"
              placeholder="ユーザー名"
              :required="true"
              :redBackground="false"
              @handleChange="this.formAddAdmin.nameAdmin = $event"
            />
          </div>

          <div class="input email">
            <InputComponent
              name="emailAdmin"
              :value="formAddAdmin.emailAdmin"
              placeholder="メールアドレス"
              :required="true"
              :redBackground="false"
              @handleChange="onChangeEmailAdmin"
              :error="errorEmailAdmin"
              :delay="true"
            />
          </div>

          <div class="input select-auth">
            <PulldownComponent
              name="authority"
              :items="selectBoxAuthority"
              item-text="name"
              item-value="value"
              :value="formAddAdmin.authority"
              label="権限"
              placeholder="すべて"
              :required="true"
              :redBackground="false"
              @change="this.formAddAdmin.authority = $event"
            />
          </div>

          <div class="input department">
            <InputComponent
              name="department"
              :value="formAddAdmin.department"
              placeholder="所属部署"
              @handleChange="this.formAddAdmin.department = $event"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-center">
          <v-btn
            class="fs-14 w-14 border-8"
            variant="outlined"
            :color="btnColor.CANCEL"
            @click="$refs.modalAddAdmin.close()"
          >
            キャンセル
          </v-btn>
          <v-btn
            :class="[
              'w-14 ml-3 fs-14 btn border-8',
              { 'button-disabled': !activeButtonAddAdmin },
            ]"
            variant="outlined"
            :color="btnColor.SET"
            :disabled="!activeButtonAddAdmin"
            @click="handleAddAdmin()"
          >
            ユーザー追加
          </v-btn>
        </div>
      </template>
    </modal-component>

    <modal-component ref="modalConfirmCloseAddAdmin" :max-width="500">
      <template #header>ユーザー追加の中断</template>
      <template #body>
        <p class="fw-400 text-white-smoke">
          変更内容が保存されていません。
          <br />
          モーダルを閉じますか？
        </p>
      </template>
      <template #footer>
        <div class="text-center">
          <v-btn
            class="fs-14 w-14 btn-confirm"
            variant="outlined"
            color="#F50057"
            @click="
              $refs.modalConfirmCloseAddAdmin.close();
              $refs.modalAddAdmin.open();
            "
          >
            いいえ
          </v-btn>
          <v-btn
            :class="['w-14 ml-3 fs-14 btn btn-confirm']"
            variant="outlined"
            color="#1565C0"
            @click="$refs.modalConfirmCloseAddAdmin.close()"
          >
            はい
          </v-btn>
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="adminAddCompleteModal"
      ref="adminAddCompleteModal"
      @handleBackAdminDetail="handleBackAdminDetail"
      @handleLinkAll="handleBackAdminDetail()"
    >
      <template #footer>
        <p class="fs-14 ml-3 px-5 pt-2 text-center">
          <a class="download-link" @click="$refs.adminAddCompleteModal.close()">
            ダッシュボードへ戻る
          </a>
        </p>
      </template>
    </modal-component>
    <!-- ユーザー追加関連ポップアップ -------------------------------------------------------------------------------------->

    <!-- 従業員登録関連ポップアップ --------------------------------------------------------------------------------------->
    <modal-component ref="modalAddStaff" class="qr-code-modal zaimane-modal">
      <template #header>従業員登録用URL/QRコード</template>

      <template #body>
        <div class="modal-body__content fs-16 ma-4">
          <p class="text-left pb-3 fw-400 text-white-smoke">
            <span class="text-danger">従業員登録用</span
            >のURL/QRコードです。店舗を選択し、アプリまたはスマートフォンのQR読取機能でQRコードを読み取ってください。
            <br />
            従業員情報更新用としては、ご利用になれませんのでご注意ください。
            <br />
          </p>

          <PageListFilterPulldownComponent
            class="h-4 text-dark fw-normal"
            :items="shopsLimit"
            itemText="nameShop"
            itemValue="idShop"
            :value="shopSelected"
            label="店舗"
            placeholder="店舗"
            :icon-clear="false"
            @selected="onGetSeletedShopRegistrationUrl($event)"
          />

          <div class="qr-code">
            <qrcode-component
              :value="registrationUrl || ''"
              :options="{ width: 300 }"
            />
          </div>

          <div class="copy-qr-code d-flex justify-space-between text-left mt-5">
            <span>URL</span>
            <b class="fs-14 mt-1 fw-500" ref="urlQrCode">
              {{ registrationUrl || "" }}
            </b>
            <i
              class="mdi mdi-content-copy pl-1"
              title="copy"
              @click="onCopyRegistrationUrl"
            >
            </i>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-center">
          <v-btn
            class="w-14 ml-3 fs-14 btn border-8"
            variant="outlined"
            :color="btnColor.SET"
            @click="$refs.modalAddStaff.close()"
          >
            閉じる
          </v-btn>
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="modalShopLink"
      ref="modalShopLink"
      :max-width="500"
      @onShopPopupFromStaffAdd="onShopPopupFromStaffAdd"
      @closeModal="$refs.modalShopLink.close()"
    />
    <!-- 従業員登録関連ポップアップ --------------------------------------------------------------------------------------->

    <modal-component
      :modalData="modalDefault"
      ref="modalDefault"
      :max-width="500"
      @closeModal="$refs.modalDefault.close()"
    />

    <teleport to="body">
      <notice-modal-component ref="noticeModal" :notice-detail="noticeDetail" />
    </teleport>

    <alert-component :alert="copyAlert" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SessionStorage from "@/common/sessionStorage";
import DashboardService from "@/services/dashboard.service";
import StaffService from "@/services/staff.service.js";
import EtcService from "@/services/etc.service.js";
import CompanyService from "@/services/company.service";
import NoticeModalComponent from "@/components/NoticeModalComponent.vue";
import PagePagerComponent from "@/components/PagePagerComponent.vue";
import PageListComponent from "@/components/PageListComponent.vue";
import PageListFilterComponent from "@/components/PageListFilterComponent.vue";
import QrcodeComponent from "@/components/QrcodeComponent";
import { handleOptionParams } from "@/common/helpers.js";
import utilFunc from "@/common/utilFunc.js";
import constFunc from "@/common/constFunc.js";
import {
  ALERT,
  POPUP_ACTION,
  EXPLAIN_ACTION,
  AUTHORITY,
  LEVEL_PLAN,
  BUTTON_COLOR,
  HTTP_RESPONSE_STATUS_CODES,
  AUTHORITY_TXT,
} from "@/common/constants.js";
import ModalFirstLogin from "@/components/pages/dashboard/ModalFirstLogin.vue";
import Logo from "@/components/Logo.vue";
import InputComponent from "@/components/InputComponent.vue";
import OfficeService from "@/services/office.service";
import PageListFilterPulldownComponent from "@/components/forms/PageListFilterPulldownComponent.vue";
import ShopService from "@/services/shop.service";
import AdminService from "@/services/admin.service.js";
import PulldownComponent from "@/components/forms/PulldownComponent.vue";
import AlertComponent from "@/components/AlertComponent.vue";

export default {
  name: "Dashboard",

  components: {
    PagePagerComponent,
    PageListComponent,
    PageListFilterComponent,
    QrcodeComponent,
    ModalFirstLogin,
    Logo,
    InputComponent,
    PageListFilterPulldownComponent,
    PulldownComponent,
    AlertComponent,
    NoticeModalComponent,
  },

  data() {
    return {
      isDataLoadDone: false,
      helloWork: null,
      alert: null,
      noticeDetail: {},

      staffList: [],
      isStaffExist: false,
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      columns: [
        { staffID: "社員番号" },
        { nameStaffRegister: "名前" },
        { nameShop: "店舗" },
        { currentStatus: "雇用状況" },
        { isReported: "偽造チェック" },
        { dataInputStatus: "入力状況" },
        { alerts: "アラート" },
      ],

      params: {
        nameStaff: null,
        staffID: null,
        idShop: null,
        alertType: null,
        currentStatus: "Working,Hired,Suspended,Status update needed",
        cardCheck: null,
        page: 0,
        size: 30,
      },

      getCurrentStatus: constFunc.getCurrentStatus,
      getAlert: constFunc.getAlert,
      getColorAlert: constFunc.getColorAlert,
      getDataInputStatus: constFunc.getDataInputStatus,
      getTotalItems: utilFunc.formatNumber,
      formatDate: utilFunc.formatDate,

      errorData: null,
      creditStatus: null,

      popupActionConst: POPUP_ACTION,
      explainActionConst: EXPLAIN_ACTION,
      levelPlan: LEVEL_PLAN,

      modalDefault: null,

      // 店舗追加関連
      formAddShop: {
        shopID: "",
        nameShop: "",
        idOffice: "",
      },
      btnColor: BUTTON_COLOR,
      officeList: [],
      officeOnlyList: [],
      errorNameShop: "",
      timeOutCheckShopExists: null,
      shopAddCompleteModal: null,
      addedShop: null,

      // ユーザー追加関連
      modalAddAdmin: {},
      adminAddCompleteModal: {},
      formAddAdmin: {
        nameAdmin: "",
        emailAdmin: "",
        authority: "",
        department: "",
      },
      errorEmailAdmin: "",
      authority: AUTHORITY,
      authorityTxt: AUTHORITY_TXT,
      selectBoxAuthority: [
        {
          name: AUTHORITY_TXT["0"],
          value: constFunc.getAuthorityByTxt(AUTHORITY_TXT["0"]),
        },
        {
          name: AUTHORITY_TXT["1"],
          value: constFunc.getAuthorityByTxt(AUTHORITY_TXT["1"]),
        },
        {
          name: AUTHORITY_TXT["2"],
          value: constFunc.getAuthorityByTxt(AUTHORITY_TXT["2"]),
        },
        {
          name: AUTHORITY_TXT["3"],
          value: constFunc.getAuthorityByTxt(AUTHORITY_TXT["3"]),
        },
      ],
      addedAdmin: null,

      // 従業員登録関連
      shopSelected: null,
      registrationUrl: null,
      copyAlert: null,
      shopsLimit: null,
      isStaffLimit: false,
      modalShopLink: null,
    };
  },

  computed: {
    ...mapState({
      shops: (state) => state.shop.shops,
      companyInfo: (state) => JSON.parse(state.companyInfo).data,
      adminInfo: (state) => JSON.parse(state.adminInfo),
      idCompany: (state) => JSON.parse(state.companyInfo).data.idCompany,
      authorityCurrent: (state) => state.authority,
    }),

    filters() {
      return [
        [
          {
            field: "nameStaff",
            type: "input",
            placeholder: "名前",
            value: "",
          },
          {
            field: "staffID",
            type: "input",
            placeholder: "社員番号",
            value: "",
          },
          {
            field: "idShop",
            type: "select",
            placeholder: "すべて",
            items: this.shops,
            itemText: "nameShop",
            itemValue: "idShop",
            label: "店舗",
            value: "",
          },
        ],

        [
          {
            field: "alertType",
            label: "アラート",
            type: "option",
            list: [
              {
                label: "警告",
                name: ALERT.ALERT_WARNING_TEXT,
                value: false,
              },
              {
                label: "不備あり",
                name: ALERT.ALERT_MISSING_INFORMATION_TEXT,
                value: false,
              },
              {
                label: "要確認",
                name: ALERT.ALERT_ATTENTION_NEEDED_TEXT,
                value: false,
              },
              {
                label: "更新対象",
                name: ALERT.ALERT_UPDATE_NEEDED_TEXT,
                value: false,
              },
            ],
          },
        ],
      ];
    },

    // 店舗追加ポップアップ関連 -------------------------------------------------------------------------------------------
    activeButtonAddShop: function () {
      return this.formAddShop.nameShop !== "" && this.errorNameShop === "";
    },

    // ユーザー追加ポップアップ関連 ----------------------------------------------------------------------------------------
    activeButtonAddAdmin() {
      return (
        this.formAddAdmin.nameAdmin !== "" &&
        this.formAddAdmin.emailAdmin !== "" &&
        this.formAddAdmin.authority !== "" &&
        this.errorEmailAdmin === ""
      );
    },
  },

  watch: {
    idCompany() {
      SessionStorage.destroyData("endTrialFiveDayNotify");
      SessionStorage.destroyData("firstLogin");
      SessionStorage.destroyData("firstLoginEndTrial");
      SessionStorage.destroyData("firstLoginUnlimited");
      this.onGetDashboardHelloWork();
      this.onGetDashboardAlert();
      this.onGetStaffList();
      if (
        this.companyInfo.popupAction &&
        this.companyInfo.popupAction != POPUP_ACTION.END_TRIAL_FIVE_DAY
      ) {
        this.handleCompanyPopupFlag(this.companyInfo.popupAction);
      }
    },
  },

  created() {
    this.onGetDashboardHelloWork();
    this.onGetDashboardAlert();
    this.onGetStaffList();

    if (
      this.companyInfo.popupAction &&
      this.companyInfo.popupAction != POPUP_ACTION.END_TRIAL_FIVE_DAY
    ) {
      this.handleCompanyPopupFlag(this.companyInfo.popupAction);
    }

    // 店舗ポップアップ関連
    this.onGetOffices();

    // 店舗取得
    this.onGetShops();
  },

  mounted() {
    if (
      this.companyInfo.notice !== null &&
      this.companyInfo.notice !== undefined
    ) {
      // 参照がなくなるため、DeepCopyでデータを記録
      this.noticeDetail = JSON.parse(JSON.stringify(this.companyInfo.notice));
      // Note: 時間遅延を設定しないとopenが出来ない
      setTimeout(() => {
        this.$refs.noticeModal.open();
      }, 1000);

      // companyInfoからnoticeを削除し、session storageに保存
      let companyInfoParse = JSON.parse(this.$store.state.companyInfo);
      companyInfoParse.data.notice = null;
      let companyInfoResult = JSON.stringify(companyInfoParse);
      SessionStorage.saveData("companyInfo", companyInfoResult);
    }
  },

  methods: {
    ...mapMutations({
      setStateParamsStaffList: "staff/setStateParamsStaffList",
      setStateParamsInputStatus: "staff/setStateParamsInputStatus",
      setStateParamsUpdateTarget: "staff/setStateParamsUpdateTarget",
      setStateParamsShopFilter: "setStateParamsShopFilter",
      setStateParamsElectronic: "hellowork/setStateParamsElectronic",
      setStateParamsContact: "contact/setStateParamsContact",
      setStateShops: "setStateShops",
    }),

    onGetDashboardHelloWork() {
      DashboardService.getDashboardHelloWork().then((res) => {
        this.helloWork = res.data;
      });
    },

    onGetDashboardAlert() {
      DashboardService.getDashboardAlert().then((res) => {
        this.alert = res.data;
      });
    },

    // get staff list
    onGetStaffList(skipCheck = false) {
      StaffService.getStaffList(this.params)
        .then((res) => {
          this.staffList = res.data;
          this.totalPages = res.pages;
          this.totalItems = res.total;
          if (!skipCheck) {
            this.isStaffExist = this.staffList.length > 0 ? true : false;
          }
        })
        .finally(() => {
          this.isDataLoadDone = true;
        });
    },

    // change params search
    getSearchParams(data) {
      switch (data.field) {
        case "nameStaff":
        case "staffID":
        case "idShop":
          this.params[data.field] = data.value || null;
          break;

        case ALERT.ALERT_WARNING_TEXT:
        case ALERT.ALERT_MISSING_INFORMATION_TEXT:
        case ALERT.ALERT_ATTENTION_NEEDED_TEXT:
        case ALERT.ALERT_UPDATE_NEEDED_TEXT:
          this.params.alertType = handleOptionParams(
            this.params.alertType,
            data.field,
            data.value
          );
          break;
      }

      const skipCheck = true

      if (data.field !== "page") {
        this.params.page = 0;
        this.currentPage = 1;
      }

      this.onGetStaffList(skipCheck);
    },

    // change page staff list
    changePage(page) {
      this.currentPage = page;
      this.params.page = page - 1;
      this.onGetStaffList();
    },

    saveSelectedStaff(idStaff) {
      SessionStorage.saveData("selectedStaff", idStaff);
    },

    // postCompanyPopupFlag
    handleCompanyPopupFlag(popupAction) {
      DashboardService.postCompanyPopupFlag(popupAction);
    },

    //post plan select
    onPostPlanSelect() {
      EtcService.postPlanSelect()
        .then((res) => {
          this.errorData = null;
          this.creditStatus = res.data.creditStatus;
        })
        .catch((err) => {
          this.errorData = err;
        })
        .finally(() => {
          this.$refs.confirmModal.close();
          this.onGetCompanyInfo();
          this.$refs.resultModal.open();
        });
    },

    //get company info
    onGetCompanyInfo() {
      CompanyService.getCompanyInfo().then((resCompany) => {
        let companyJsonRes = JSON.stringify(resCompany);
        this.$store.state.companyInfo = companyJsonRes;
        SessionStorage.saveData("companyInfo", companyJsonRes);
      });
    },

    getNextMonth() {
      const date = new Date();
      date.setMonth(date.getMonth() + 1);
      return date.toISOString().substr(0, 10);
    },

    // 店舗追加ポップアップ関連--------------------------------------------------------------------------------------------
    closeModalAddShop() {
      if (
        this.formAddShop.shopID ||
        this.formAddShop.nameShop ||
        this.formAddShop.idOffice
      ) {
        this.$refs.modalConfirmCloseAddShop.open();
      }
    },
    onGetOffices() {
      var paramsGetOffice = { isNotRelated: true };
      OfficeService.getOffices(paramsGetOffice).then((res) => {
        const copy_data = JSON.parse(JSON.stringify(res.data));
        this.officeOnlyList = copy_data.slice(1);
      });
    },
    onGetShops() {
      ShopService.getShops().then((res) => {
        this.setStateShops(res.data);
        this.onGetSeletedShopRegistrationUrl();
      });
    },
    changeNameShop(nameShop) {
      clearTimeout(this.timeOutCheckShopExists);

      this.formAddShop.nameShop = nameShop;

      if (!nameShop) {
        this.errorNameShop = "";
        return;
      }

      if (nameShop) {
        this.timeOutCheckShopExists = setTimeout(() => {
          this.onGetShopExists(nameShop);
        }, 1000);
      }
    },
    onGetShopExists(nameShop) {
      const params = {
        nameShop: nameShop,
      };
      ShopService.getShopExists(params).then((res) => {
        if (res.data) {
          this.errorNameShop = "この店舗はすでに登録されています";
          return;
        }
        this.errorNameShop = "";
      });
    },
    handleAddShop() {
      let formAddShop = this.formAddShop;

      if (this.formAddShop.shopID === "") {
        delete this.formAddShop.shopID;
      }

      ShopService.addShop(formAddShop)
        .then((res) => {
          this.shopAddCompleteModal = res;

          // reset form add shop
          this.formAddShop = {
            shopID: "",
            nameShop: "",
            idOffice: "",
          };

          this.$refs.modalAddShop.close();
          this.errorNameShop = "";
          this.$refs.shopAddCompleteModal.open();
          this.addedShop = res.data;

          this.shopAddCompleteModal.button = [
            {
              color: BUTTON_COLOR.SET,
              text: "追加した店舗を確認する",
              action: "handleBackShopDetail",
            },
          ];

          // update state shops
          this.onGetShops();

          // 店舗が存在しない時点から店舗を追加した場合
          if (this.isNotExistShop === true) {
            this.onGetShopManualMake();
          }
        })
        .catch((err) => {
          if (
            this.$store.state.errorStatus ===
            HTTP_RESPONSE_STATUS_CODES.STATUS_400_BAD_REQUEST
          ) {
            this.errorNameShop = err.message;
            this.$refs.modalAddShop.open();
            return;
          }

          this.$refs.modalAddShop.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },
    handleBackShopDetail() {
      this.$router.push({
        name: "shopDetail",
        params: { idShop: this.addedShop},
        query: { fromBefore: "dashboard" },
      });
    },
    // 店舗追加ポップアップ関連--------------------------------------------------------------------------------------------

    // ユーザー追加ポップアップ関連-----------------------------------------------------------------------------------------
    closeModalAddAdmin() {
      if (
        this.formAddAdmin.nameAdmin ||
        this.formAddAdmin.emailAdmin ||
        this.formAddAdmin.authority ||
        this.formAddAdmin.department
      ) {
        this.$refs.modalConfirmCloseAddAdmin.open();
      }
    },
    resetFormAddAdmin() {
      this.formAddAdmin = {
        nameAdmin: "",
        emailAdmin: "",
        authority: "",
        department: "",
      };
      this.errorEmailAdmin = "";
    },
    handleAddAdmin() {
      let formAddAdmin = this.formAddAdmin;
      if (this.formAddAdmin.department === "") {
        formAddAdmin = {
          nameAdmin: this.formAddAdmin.nameAdmin,
          emailAdmin: this.formAddAdmin.emailAdmin,
          authority: this.formAddAdmin.authority,
        };
      }

      AdminService.addAdmin(formAddAdmin)
        .then((res) => {
          this.idNewAdmin = res.data.data.idAdmin;

          this.formAddAdmin = {
            nameAdmin: "",
            emailAdmin: "",
            authority: "",
            department: "",
          };

          this.$refs.modalAddAdmin.close();
          this.$refs.adminAddCompleteModal.open();
          this.addedAdmin = res.data.data.idAdmin;

          this.adminAddCompleteModal = {
            result: res.data.result,
            message: {
              header: res.data.message.header,
              title: res.data.message.title,
              content: res.data.data.nameAdmin + "を追加しました",
            },
          };

          this.adminAddCompleteModal.button = [
            {
              color: BUTTON_COLOR.SET,
              text: "追加したユーザーを確認する",
              action: "handleBackAdminDetail",
            },
          ];
        })
        .catch((err) => {
          this.$refs.modalAddAdmin.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },
    onChangeEmailAdmin(value) {
      this.formAddAdmin.emailAdmin = value;

      if (!value) {
        this.errorEmailAdmin = "";
        return;
      }

      if (!utilFunc.validateEmail(value)) {
        this.errorEmailAdmin = "正しいメールアドレスを入力してください";
        return;
      }
      this.errorEmailAdmin = "";

      AdminService.checkExistEmail(value)
        .then((res) => {
          if (res.data) {
            this.errorEmailAdmin = "このメールアドレスはすでに登録されています";
            return;
          }
          this.errorEmailAdmin = "";
        })
        .catch(() => {
          return;
        });
    },
    redirectLinkShopAdmin() {
      this.saveAccountAdmin({
        authority: this.formAddAdmin.authority,
        department: this.formAddAdmin.department,
        emailAdmin: this.formAddAdmin.emailAdmin,
        idAdmin: this.idNewAdmin,
        nameAdmin: this.formAddAdmin.nameAdmin,
      });

      this.$router.push({
        name: "shopListAdmin",
        params: { idAdmin: this.idNewAdmin },
      });
    },
    handleBackAdminDetail() {
      this.$route.params.idAdmin = this.addedAdmin;
      this.$router.push({
        name: "adminDetail",
        query: { idAdminDashboard: this.addedAdmin, fromBefore: "dashboard" },
      });
    },
    // ユーザー追加ポップアップ関連-----------------------------------------------------------------------------------------

    // 従業員登録ポップアップ関連-------------------------------------------------------------------------------------------
    openModalAddStaff() {
      StaffService.getIsStaffLimit().then((res) => {
        if (res.data) {
          this.modalDefault = {
            result: "error",
            message: {
              header: "従業員登録用URL/QRコード",
              title: "フリープランでの登録上限に達しました",
              content:
                "フリープランで登録可能枠(30名)の上限に達しているため、<br />新たに従業員を登録することはできません。<br />ダッシュボード画面上部の案内をご確認ください。",
            },
          };
          this.$refs.modalDefault.open();
          return;
        }

        if (
          this.shopsLimit.length === 0 &&
          this.authorityCurrent === this.authority.MASTER
        ) {
          this.modalShopLink = {
            result: "error",
            message: {
              header: "従業員登録用URL/QRコード",
              title: "従業員を登録させる店舗が存在しません",
              content:
                "従業員登録のためには店舗の追加が必要です。<br />このまま店舗の追加にお進みください。",
            },
            button: [
              {
                color: BUTTON_COLOR.SET,
                text: "店舗を追加",
                action: "onShopPopupFromStaffAdd",
              },
            ],
          };
          this.$refs.modalShopLink.open();
          return;
        }

        if (
          this.shopsLimit.length === 0 &&
          this.authorityCurrent !== this.authority.MASTER
        ) {
          this.modalDefault = {
            result: "error",
            message: {
              header: "従業員登録用URL/QRコード",
              title: "従業員を登録させる店舗が存在しません",
              content:
                "従業員登録のためには店舗の追加が必要です。<br />管理者の方にお問い合わせください",
            },
          };
          this.$refs.modalDefault.open();
          return;
        }

        this.$refs.modalAddStaff.open();
      });
    },
    onShopPopupFromStaffAdd() {
      this.$refs.modalShopLink.close();
      this.$refs.modalAddShop.open();
    },
    onGetSeletedShopRegistrationUrl(selected = null) {
      if (selected) {
        this.shopSelected = selected;
        SessionStorage.saveData("dashBoardStaffSelectShop", this.shopSelected);
      } else {
        const selectedShop = SessionStorage.getData("dashBoardStaffSelectShop");
        if (selectedShop) {
          this.shopSelected = selectedShop;
        }
      }

      this.shopsLimit = JSON.parse(JSON.stringify(this.shops));
      this.shopsLimit.forEach((shop, index) => {
        if (shop.nameShop === "未所属") {
          this.shopsLimit.splice(index, 1);
        }
      });

      this.registrationUrl = null;
      this.shopsLimit.forEach((shop) => {
        if (this.shopSelected == null) {
          this.shopSelected = shop.idShop;
          this.registrationUrl = shop.registrationUrl;
          return true;
        } else {
          if (shop.idShop == this.shopSelected) {
            this.shopSelected = shop.idShop;
            this.registrationUrl = shop.registrationUrl;
            return true;
          }
        }
      });

      // もし前回選択した店舗がなくなった場合のための処理
      if (this.registrationUrl == null && this.shopsLimit.length > 0) {
        this.shopSelected = this.shopsLimit[0].idShop;
        this.registrationUrl = this.shopsLimit[0].registrationUrl;
      }
    },
    onCopyRegistrationUrl() {
      utilFunc.copyURL(this.$refs.urlQrCode);

      this.copyAlert = {
        text: "コピーしました。",
        type: "success",
        timer: 2000,
      };
    },
    // 従業員登録ポップアップ関連-------------------------------------------------------------------------------------------
  },
};
</script>
