const state = () => ({
  paramsZaimane: {
    nameStaff: null,
    cardId: null,
    idShop: null,
    isReported: false,
    regStatus: null,
    cardCheck: null,
    page: 0,
    size: 30,
  },

  paramsInputStatus: {
    nameStaff: null,
    staffID: null,
    idShop: null,
    dataInputStatus: null,
    alertName: null,
    regType: null,
    page: 0,
    size: 30,
    sort: null,
    isAsc: true,
  },

  paramsStaffList: {
    nameStaff: null,
    staffID: null,
    idShop: null,
    alertType: null,
    currentStatus: "Working,Hired,Suspended,Status update needed",
    approvalStatus: null,
    cardCheck: null,
    page: 0,
    size: 30,
    sort: null,
    isAsc: true,
  },

  paramsApprovalList: {
    nameStaff: null,
    staffID: null,
    idShop: null,
    requestedToSelf: false,
    page: 0,
    size: 30,
    sort: null,
    isAsc: true,
  },

  sortHandler: {
    isSortActive: null,
    isAscStaffID: true,
    isAscNameStaffRegister: true,
    isAscRegistrationDate: true,
    isAscRequestedDate: true,
    isAscApprovedDate: true,
  },

  pamramsUpdateTarget: {
    nameStaff: null,
    staffID: null,
    idShop: null,
    alertName: null,
    dataUpdateStatus: null,
    size: 30,
    page: 0,
  },

  newRegShop: null,
  pageBack: "staffList",
  pageBackDetail: "staffBasicInfo",
});

const mutations = {
  setStateParamsZaimane(state, data) {
    state.paramsZaimane = {
      ...state.paramsZaimane,
      ...data,
    };
  },

  setStateParamsInputStatus(state, data) {
    state.paramsInputStatus = {
      ...state.paramsInputStatus,
      ...data,
    };
  },

  setStateParamsStaffList(state, data) {
    state.paramsStaffList = {
      ...state.paramsStaffList,
      ...data,
    };
  },

  setStateParamsApprovalList(state, data) {
    state.paramsApprovalList = {
      ...state.paramsApprovalList,
      ...data,
    };
  },

  setStateSortHandler(state, data) {
    state.sortHandler = {
      ...state.sortHandler,
      ...data,
    };
  },

  setStateParamsUpdateTarget(state, data) {
    state.pamramsUpdateTarget = {
      ...state.pamramsUpdateTarget,
      ...data,
    };
  },

  setStateNewRegShop(state, data) {
    state.newRegShop = data;
  },

  resetSortHandler(state) {
    state.sortHandler = {
      isSortActive: null,
      isAscStaffID: true,
      isAscNameStaffRegister: true,
      isAscRegistrationDate: true
    };
  },
  resetParamsInputStatus(state) {
    state.paramsInputStatus = {
      nameStaff: null,
      staffID: null,
      idShop: null,
      dataInputStatus: null,
      alertName: null,
      regType: null,
      page: 0,
      size: 30,
      sort: null,
      isAsc: true,
    };
  },
  resetParamsStaffList(state) {
    state.paramsStaffList = {
      nameStaff: null,
      staffID: null,
      idShop: null,
      alertType: null,
      currentStatus: "Working,Hired,Suspended,Status update needed",
      cardCheck: null,
      page: 0,
      size: 30,
      sort: null,
      isAsc: true,
    };
  },
  resetParamsApprovalList(state) {
    state.paramsApprovalList = {
      nameStaff: null,
      staffID: null,
      idShop: null,
      targetType: "Incoming",
      page: 0,
      size: 30,
      sort: null,
      isAsc: true,
    };
  },
  resetParamsUpdateTarget(state) {
    state.pamramsUpdateTarget = {
      nameStaff: null,
      staffID: null,
      idShop: null,
      alertName: null,
      dataUpdateStatus: null,
      size: 30,
      page: 0,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
